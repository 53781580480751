import React, { useCallback, useMemo } from "react";

import './styles.css';
import { MethodProps } from "./interfaces";
import { useScreensize } from "../../hooks";
import { Swipper } from "./swipper";
import { Carousel } from "./Carousel";

//@ts-ignore
import Slide from 'react-reveal/Slide';
import { LanguagesEnum } from "../../enums";

export const Method:React.FC<MethodProps> = ({
    localLanguage
})=> {

    const {isDesktop, isMobile} = useScreensize();

    const methodStepsTab_1 = useMemo(()=> {
        return [
            {
                step: 1,
                illustration: '👋',
                title: localLanguage === LanguagesEnum.FR ? 'Définition du projet' : 'Project Definition',
                description: (
                    <div className="descriptionMethodItemContainer">
                        <p className="descriptionTxt_step_1">
                            {
                                localLanguage === LanguagesEnum.FR ?
                                'Nous identifions vos enjeux et problématiques.' : 'We identify your challenges and issues.'
                            }
                        </p>
                        <ul>
                            <li className="descriptionLiTxt_step_1">
                                {
                                    localLanguage === LanguagesEnum.FR ? 
                                    'Quels sont vos besoins, vos objectifs business ?' : 'What are your needs and business objectives?'
                                }
                            </li>
                            <li className="descriptionLiTxt_step_1">
                                {
                                    localLanguage === LanguagesEnum.FR ?
                                    'Quel marché adressez-vous, quelles en sont les caractéristiques ?' : 'Which market are you targeting, and what are its characteristics?'
                                }
                            </li>
                            <li className="descriptionLiTxt_step_1">
                                {
                                    localLanguage === LanguagesEnum.FR ?
                                    'Quelles sont les solutions que vous souhaitez voir implémenter afin de répondre aux problématiques de vos futurs clients ou utilisateurs ?'
                                    : 'What solutions do you wish to implement to address the challenges of your future clients or users?'
                                }
                            </li>
                        </ul>
                    </div>
                ),
                classTitle: 'step_1_title',
                class: 'methodItemContainer_1',
                illustrationClass: 'methodItemillustrationClass_1',
                stepClass: 'stepClass_1',
            },
            {
                step: 3,
                illustration: '👥',
                title: 'Users flows',
                description: (
                    <div className="descriptionMethodItemContainer">
                        <p className="descriptionTxt_step_2">
                            {
                                localLanguage === LanguagesEnum.FR ? 
                                "Organisation des parcours utilisateurs, ou 'user flows', afin de faciliter la compréhension des différentes étapes que l'utilisateur traversera pour atteindre un but précis."
                                : "Organizing user journeys, or 'user flows,' to facilitate the understanding of the various steps the user will go through to achieve a specific goal."
                            }
                            
                        </p>
                    </div>
                ),
                classTitle: 'step_2_title',
                class: 'methodItemContainer_2',
                illustrationClass: 'methodItemillustrationClass_2',
                stepClass: 'stepClass_2',
            },
            {
                step: 5,
                illustration: '🧑🏼‍🎨',
                title: localLanguage === LanguagesEnum.FR ? 'Maquettes' : 'Mockups',
                description: (
                    <div className="descriptionMethodItemContainer">
                        {
                            localLanguage === LanguagesEnum.FR ? (
                                <p className="descriptionTxt_step_2">
                                    Nous commençons la <label>conception graphique. </label> 
                                    En collaboration avec vous, nous 
                                    <label> établissons votre identité visuelle </label> 
                                    et les éléments (photos, logos, icônes, animations) à incorporer ultérieurement. De cette manière, nous pouvons
                                    <label> concevoir de manière efficace tous les écrans de votre produit à venir.</label>
                                </p>
                            ) : (
                                <p className="descriptionTxt_step_2">
                                    We start the <label>graphic design. </label> 
                                    In collaboration with you, we
                                    <label> establish your visual identity </label> 
                                    and the elements (photos, logos, icons, animations) to incorporate later. This way, we can 
                                    <label> efficiently design all the screens for your upcoming product.</label>
                                </p>
                            )
                        }
                    </div>
                ),
                classTitle: 'step_2_title',
                class: 'methodItemContainer_2',
                illustrationClass: 'methodItemillustrationClass_2',
                stepClass: 'stepClass_2',
            },
            {
                step: 7,
                illustration: '🛠️',
                title: 'Maintenance',
                description: (
                    <div className="descriptionMethodItemContainer">
                        {
                            localLanguage === LanguagesEnum.FR ? (
                                <p className="descriptionTxt_step_1_bis">
                                    Nous sommes là pour 
                                    <label> vous accompagner </label> 
                                    tout au long de l'évolution et de la 
                                    <label> maintenance de votre produit. </label> 
                                    Besoin d’une mise à jour? Nous sommes là.
                                </p>
                            ) : (
                                <p className="descriptionTxt_step_1_bis">
                                    We are here to
                                    <label> support you </label> 
                                    throughout the evolution and
                                    <label> maintenance of your product. </label> 
                                    Need an update? We're here.
                                </p>
                            )
                        }
                    </div>
                ),
                classTitle: 'step_3_title',
                class: 'methodItemContainer_3',
                illustrationClass: 'methodItemillustrationClass_3',
                stepClass: 'stepClass_3',
            },
        ];
    }, [localLanguage]);

    const methodStepsTab_2 = useMemo(()=> {
        return [
            {
                step: 2,
                illustration: '📒',
                title: localLanguage === LanguagesEnum.FR ? 'Cahier des charges' : 'Specifications',
                description: (
                    <div className="descriptionMethodItemContainer">
                        {
                            localLanguage === LanguagesEnum.FR ? (
                                <p className="descriptionTxt_step_1_bis">
                                    Nous vous accompagnons dans la 
                                    <label> définition d'un cahier des charges </label> 
                                    ainsi que des caractéristiques techniques précises nécessaires pour démarrer le projet sur des fondations solides + établissement de la
                                    <label> roadmap du projet</label>
                                </p>
                            ) : (
                                <p className="descriptionTxt_step_1_bis">
                                    We assist you in defining a
                                    <label> specifications document </label> 
                                    as well as the specific technical requirements needed to start the project on solid foundations + establishing the
                                    <label> project roadmap.</label>
                                </p>
                            )
                        }
                    </div>
                ),
                classTitle: 'step_1_title',
                class: 'methodItemContainer_1',
                illustrationClass: 'methodItemillustrationClass_1',
                stepClass: 'stepClass_1',
            },
            {
                step: 4,
                illustration: '🎞️️',
                title: 'Wireframes',
                description: (
                    <div className="descriptionMethodItemContainer">
                        {
                            localLanguage === LanguagesEnum.FR ? (
                                <p className="descriptionTxt_step_2">
                                    Cette étape consiste à 
                                    <label> déterminer les sections et les éléments que votre interface future </label> 
                                    doit inclure pour optimiser son 
                                    <label> ergonomie. </label> 
                                    Pour cela, nous 
                                    <label> concevons des écrans uniquement axés sur les fonctionnalités</label>
                                    , dépourvus de tout élément lié au design et à l'esthétique.
                                </p>
                            ) : (
                                <p className="descriptionTxt_step_2">
                                    This step involves
                                    <label> determining the sections and elements that your future </label> 
                                    interface should include to optimize its
                                    <label> usability. </label> 
                                    To do this, we
                                    <label> design screens focused solely on functionality, </label>
                                    devoid of any elements related to design and aesthetics.
                                </p>
                            )
                        }
                    </div>
                ),
                classTitle: 'step_2_title',
                class: 'methodItemContainer_2',
                illustrationClass: 'methodItemillustrationClass_2',
                stepClass: 'stepClass_2',
            },
            {
                step: 6,
                illustration: '🧑‍💻',
                title: localLanguage === LanguagesEnum.FR ? 'Développement' : 'Development',
                description: (
                    <div className="descriptionMethodItemContainer">
                        {
                            localLanguage === LanguagesEnum.FR ? (
                                <p className="descriptionTxt_step_1_bis">
                                    Conseil sur l’ <label>architecture et les technologies adaptées à votre projet. </label> 
                                    Nous divisons les développements par lots fonctionnels pour que vous puissiez 
                                    <label> tester des parcours utilisateurs aboutis. </label> 
                                    Nous prenons en compte 
                                    <label> vos retours </label> 
                                    au fur et à mesure et pouvons revoir les priorités et redéfinir la roadmap en fonction des besoins du 
                                    <label> projet.</label>
                                </p>
                            ) : (
                                <p className="descriptionTxt_step_1_bis">
                                    Advice on the <label>architecture and technologies suitable for your project. </label> 
                                    We divide development into functional batches so that you can
                                    <label> test completed user journeys. </label> 
                                    We take
                                    <label> your feedback </label> 
                                    into account as we go and can reevaluate priorities and redefine the roadmap based on  
                                    <label> project needs.</label>
                                </p>
                            )
                        }
                    </div>
                ),
                classTitle: 'step_3_title',
                class: 'methodItemContainer_3',
                illustrationClass: 'methodItemillustrationClass_3',
                stepClass: 'stepClass_3',
            },
            {
                step: 8,
                illustration: '📈',
                title: localLanguage === LanguagesEnum.FR ? 'Évolution' : 'Evolution',
                description: (
                    <div className="descriptionMethodItemContainer">
                        {
                            localLanguage === LanguagesEnum.FR ? (
                                <p className="descriptionTxt_step_1_bis">
                                    L'utilisation de votre produit pourrait éventuellement nécessiter le 
                                    <label> développement de nouvelles fonctionnalités. </label> 
                                    Nous vous offrons notre expertise pour vous aider à les hiérarchiser et vous
                                    <label> conseiller sur la meilleure façon de les intégrer de manière pertinente.</label>
                                </p>
                            ) : (
                                <p className="descriptionTxt_step_1_bis">
                                    The use of your product may eventually require the
                                    <label> development of new features. </label> 
                                    We offer our expertise to assist you in prioritizing them and provide
                                    <label> guidance on the best way to integrate them effectively.</label>
                                </p>
                            )
                        }
                    </div>
                ),
                classTitle: 'step_3_title',
                class: 'methodItemContainer_3',
                illustrationClass: 'methodItemillustrationClass_3',
                stepClass: 'stepClass_3',
            },
        ];
    }, [localLanguage]);

    const renderMobileSwipper = useCallback(()=> {
        if(isDesktop) return;

        const concatMethodSteps = methodStepsTab_1.concat(methodStepsTab_2);
        const sortedMethodSteps = concatMethodSteps.sort((a, b) => a.step - b.step);
        
        const initialCards = sortedMethodSteps.map((element, i)=> {
            return (
                <div 
                    key={i}
                    className={element.class}
                >
                    <div className={element.stepClass}>
                        <label>{element.step}</label>
                    </div>
                    <div className="methodItemHeaderContainer">
                        <div className={element.illustrationClass}>
                            <div>
                                {element.illustration}
                            </div>
                        </div>
                        <p className={element.classTitle}>
                            {element.title}
                        </p>
                    </div>
                    {element.description}
                </div>
            )
        });
        
        return (
            <div className="swipperContainer">
                <Slide bottom>
                    <Carousel 
                        CARDS={initialCards}
                    />
                </Slide>
            </div>
        )
    }, [isDesktop, methodStepsTab_1, methodStepsTab_2]);

    return (
        <div className="methodContainer">
            {
                isMobile ? renderMobileSwipper() : (
                    <div className="methodRowContainer">
                        <div className="methodSubContainer">
                        {
                            methodStepsTab_1.map((element, i)=> {
                                return (
                                    <Slide left>
                                        <div 
                                            key={i}
                                            className={element.class}
                                        >
                                            <div className={element.stepClass}>
                                                <label>{element.step}</label>
                                            </div>
                                            <div className="methodItemHeaderContainer">
                                                <div className={element.illustrationClass}>
                                                    <div>
                                                        {element.illustration}
                                                    </div>
                                                </div>
                                                <p className={element.classTitle}>
                                                    {element.title}
                                                </p>
                                            </div>
                                            {element.description}
                                        </div>
                                    </Slide>
                                )
                            })
                        }
                        </div>
                        <div className="methodSubContainer">
                        {
                            methodStepsTab_2.map((element, i)=> {
                                return (
                                    <Slide right>
                                        <div 
                                            key={i}
                                            className={element.class}
                                        >
                                            <div className={element.stepClass}>
                                                <label>{element.step}</label>
                                            </div>
                                            <div className="methodItemHeaderContainer">
                                                <div className={element.illustrationClass}>
                                                    <div>
                                                        {element.illustration}
                                                    </div>
                                                </div>
                                                <p className={element.classTitle}>
                                                    {element.title}
                                                </p>
                                            </div>
                                            {element.description}
                                        </div>
                                    </Slide>
                                )
                            })
                        }
                        </div>
                    </div>
                )
            }
        </div>
    )
}