import React from "react";
import './styles.css';
import { ActionButtonProps } from "./interfaces";
import { LanguagesEnum } from "../../enums";

export const ActionButton:React.FC<ActionButtonProps> = ({
    onContactUsPress,
    localLanguage
})=> {

    

    return (
        <div 
            className="actionButton"
            onClick={onContactUsPress}
        >
            <label>
                {
                    localLanguage === LanguagesEnum.FR ? 'Discutons de votre projet' : "Let's discuss your project"
                }
                
            </label>

            <div className="pastil" />
        </div>
    )
}