import React, { useState, useRef } from 'react';
import './Carousel.css';

import ArrowLeftWithBG from '../../assets/icons/arrowLeftWithBG.svg';
import ArrowRightWithBG from '../../assets/icons/arrowRightWithBG.svg';

interface CarouselProps {
  CARDS: any[]
}

export const Carousel:React.FC<CarouselProps> = ({
  CARDS
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const carouselRef = useRef<HTMLDivElement | null>(null);

  const dragStartX = useRef<number>(0);

  const handleDragStart = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    const clientX = 'clientX' in e ? e.clientX : e.touches[0].clientX;
    dragStartX.current = clientX;
  };

  const handleDragEnd = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    const clientX = 'clientX' in e ? e.clientX : e.changedTouches[0].clientX;
    const dragDistance = clientX - dragStartX.current;
  
    if (dragDistance > 100 && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else if (dragDistance < -100 && currentIndex < CARDS.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % CARDS.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + CARDS.length) % CARDS.length);
  };

  return (
    <div 
      className="carousel-wrapper"
    >
      <div
        className='button'
        onClick={prevSlide}
      >
        <img 
          src={ArrowLeftWithBG}
          alt='arrox left'
        />
      </div>
      
      <div 
        className="carousel-container"
        style={{
          width: window.innerWidth
        }}
      >
        <div
            className="carousel"
            ref={carouselRef}
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            onMouseDown={handleDragStart}
            onMouseUp={handleDragEnd}
            onTouchStart={handleDragStart}
            onTouchEnd={handleDragEnd}
        >
            {CARDS.map((card, index) => (
              <div className={`card`} key={index}>
                {card}
              </div>
            ))}
        </div>
      </div>

      <div
        className='button'
        onClick={nextSlide}
      >
        <img 
          src={ArrowRightWithBG}
          alt='arrox left'
        />
      </div>

      <div className="dots">
        {CARDS.map((_, index) => (
          <span
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            key={index}
          ></span>
        ))}
      </div>
    </div>
  );
};

