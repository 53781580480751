import React, { useCallback, useMemo, useState } from "react";
import './styles.css';
import { TeamProps } from "./interfaces";
import Dribble from '../../assets/logos/dribble.svg';
import GithubLogo from '../../assets/logos/github.svg';
import MerrillAvatar from '../../assets/teamAvatars/merrill.png';
import HamedAvatar from '../../assets/teamAvatars/hamed.png';
import MerrilSkillsLogos from '../../assets/logos/merrill_skills_logos.svg';
import HamedSkillsLogos from '../../assets/logos/hamed_skills_logos.svg';
import ArrowDown from '../../assets/icons/arrowDown.svg';
import { useScreensize } from "../../hooks";

//@ts-ignore
import Slide from 'react-reveal/Slide';
import { LanguagesEnum } from "../../enums";

export const Team:React.FC<TeamProps> = ({
    localLanguage
})=> {

    const {isMobile} = useScreensize();

    const [showDescriptionMerrill, setShowDescriptionMerrill] = useState<boolean>(false);
    const [showDescriptionHamed, setShowDescriptionHamed] = useState<boolean>(false);

    const learnMore = useMemo(()=> {
        return localLanguage === LanguagesEnum.FR ? 'En savoir plus' : 'Learn more'
    }, [localLanguage]);

    const teamInfosList = useMemo(()=> {
        return [
            {
                avatar: MerrillAvatar,
                name: 'Merrill',
                icon: Dribble,
                function: 'Product Designer',
                functionColor: "#FFC4BF",
                skillsLogos: MerrilSkillsLogos,
                description: localLanguage === LanguagesEnum.FR ? (
                    <p className="memberDescriptionTxt">
                        <label className="letterTxt">D</label>epuis toujours passionné par le <label>design de produits</label>,
                        Merrill s'efforce de perfectionner cet art en prêtant 
                        <label> une attention toute particulière aux détails. </label> 
                        Constamment à la recherche de nouvelles compétences pour rendre les produits que nous utilisons au quotidien 
                        <label> cohérents à la fois visuellement et dans leur utilisation, </label> 
                        il s'engage à toujours concrétiser vos idées 
                        <label>en respectant une logique à la fois technique et visuelle</label> 🧑🏻‍🎨.
                    </p>
                ) : (
                    <p className="memberDescriptionTxt">
                        <label className="letterTxt">A</label>lways passionate about <label>product design, </label>
                        strives to perfect this art by paying 
                        <label> meticulous attention to details. </label> 
                        Continuously seeking new skills to make the products we use daily both 
                        <label> visually consistent and user-friendly, </label> 
                        he is committed to bringing your ideas 
                        <label>to life with a blend of technical and visual logic</label> 🧑🏻‍🎨.
                    </p>
                )
            },
            {
                avatar: HamedAvatar,
                name: 'Hamed',
                icon: GithubLogo,
                function: localLanguage === LanguagesEnum.FR ? 'Développeur' : 'Developer',
                functionColor: "#FFE49C",
                skillsLogos: HamedSkillsLogos,
                description: localLanguage === LanguagesEnum.FR ? (
                    <p className="memberDescriptionTxt">
                        <label className="letterTxt">F</label>ervant guerrier et <label>codeur intrépide</label>, 
                        Hamed a plus de <label>5 ans d’expérience </label> 
                        en temps que <label>développeur Full-Stack</label> confirmé. Habile de ses 10 doigts, il rendra possible toutes vos idées et vos projets les plus exigeants techniquement. Il est prêt à relever (presque) tous les défis et 
                        <label> apprécie particulièrement de nouveaux challenges</label> 🧑🏿‍💻.
                    </p>
                ) : (
                    <p className="memberDescriptionTxt">
                        <label className="letterTxt">A</label> fervent warrior and <label>fearless coder</label>, 
                        Hamed has over <label>5 years of experience </label> 
                        as a seasoned <label>Full-Stack developer.</label> Skillful with all ten fingers, he will make all your technically demanding ideas and projects possible. He is ready 
                        <label> to take on (almost) any challenge and particularly enjoys new challenges</label> 🧑🏿‍💻.
                    </p>
                )
            }
        ]
    }, [localLanguage]);

    const handleShowdesciption = useCallback((i: number)=> {
        if(i === 0){
            setShowDescriptionMerrill(true);
        } else {
            setShowDescriptionHamed(true);
        }
    }, []);

    const MemberDescription = useCallback(({ 
        showDescription, 
        member, 
        learnMore, 
        handleShowDescription, 
        index,
    }: any) => (
        <>
            {showDescription ? (
                <Slide bottom>
                    <div className="memberDesciptionContainer">
                        {member.description}
                        <img 
                            src={member.skillsLogos}
                            alt="logos"
                            className="SkillslogosImg"
                        />
                    </div>
                </Slide>
            ) : (
                <Slide bottom>
                    <div 
                        className="showMoreContainer"
                        onClick={() => handleShowDescription(index)}
                    >
                        <label>{learnMore}</label>
                        <img src={ArrowDown} alt="arrow down" className="arrowDown" />
                    </div>
                </Slide>
            )}
        </>
    ), []);

    return (
        <div className="teamContainer">
            {
                teamInfosList.map((member, i)=> {
                    return (
                        <div
                            key={i}
                            className="memberContainer"
                            style={(isMobile && i === 1) ? {
                                marginTop: 60
                            } : {}}
                        >
                            <Slide bottom>
                                <img 
                                    src={member.avatar} 
                                    alt={member.name} 
                                    className="avatar"
                                />
                            </Slide>
                            <Slide bottom>
                                <p className="memberNameTxt">
                                    {member.name} <img src={member.icon} alt="icon logo" width={15} height={15} />
                                </p>
                            </Slide>
                            <Slide bottom>
                                <p
                                    style={{color: member.functionColor}}
                                    className="memberFunction"
                                >
                                    {member.function}
                                </p>
                            </Slide>

                            <MemberDescription 
                                showDescription={i === 0 ? showDescriptionMerrill : showDescriptionHamed} 
                                member={member} 
                                learnMore={learnMore} 
                                handleShowDescription={handleShowdesciption} 
                                index={i}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}