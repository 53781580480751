import React, { useCallback, useEffect, useMemo, useState } from "react";
import './styles.css';
import { HeaderProps } from "./interfaces";
import PopKodeLogoMax from '../../assets/logos/logo_max.svg';
import Menu from '../../assets/icons/menu.svg'
import LogoMobil from '../../assets/logos/logo_mobil.svg';
import { ActionButton } from "../ActionButton";
import { useScreensize } from "../../hooks";

//@ts-ignore
import Fade from 'react-reveal/Fade';
//@ts-ignore
import Slide from 'react-reveal/Slide';
import { LanguagesEnum } from "../../enums";

export const Header:React.FC<HeaderProps> = ({
    localLanguage,
    onLogoPress,
    onNavItemPress,
    onContactUsPress,
})=> {

    const {isDesktop, isAndroid} = useScreensize();
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const navItems = useMemo(()=> {
        return [
            {
                name: localLanguage === LanguagesEnum.FR ? 'Méthode' : 'Method',
                ref: '#method'
            },
            {
                name: localLanguage === LanguagesEnum.FR ? 'Références' : 'References',
                ref: '#references'
            },
            {
                name: localLanguage === LanguagesEnum.FR ? 'Équipe' : 'Team',
                ref: '#team'
            }
        ];
    }, [localLanguage]);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 600) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
    
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const onToggleMenu = useCallback(()=> {
        setIsOpenMenu(!isOpenMenu);
    }, [isOpenMenu]);

    const handleModalLogoPress = useCallback(()=> {
        onLogoPress();
        onToggleMenu();
    }, [onToggleMenu, onLogoPress]);

    const renderGetInTouch = useCallback(()=> {
        return (
            <div 
                className="contactUsButton"
                onClick={onContactUsPress}
            >
                <label>
                    Get in touch 👋
                </label>
            </div>
        )
    }, [onContactUsPress]);

    const renderDesktopNav = useCallback(()=> {
        return (
            <div 
                className={scrolled ? 
                    "navContainer navContainer--scrolled" : "navContainer"
                }
            >
                <img 
                    height={scrolled ? 60 : 70}
                    src={LogoMobil}
                    alt="logo popkode extend"
                    onClick={onLogoPress}
                    className="logoHeader"
                />

                <div className="navItemsContainer">
                    {
                        navItems.map((item, i)=> {
                            return (
                                <label
                                    key={i}
                                    className="navItem"
                                    onClick={()=> onNavItemPress(item.ref)}
                                >
                                    {item.name}
                                </label>
                            )
                        })
                    }

                    {renderGetInTouch()}
                </div>
            </div>
        )
    }, [
        navItems, 
        onLogoPress, 
        onNavItemPress, 
        renderGetInTouch, 
        scrolled,
    ]);

    const renderMobilNav = useCallback(()=> {
        return (
            <div 
                className={scrolled ? 
                    "navContainer navContainer--scrolled" : "navContainer"
                }
            >
                <img 
                    width={120}
                    src={LogoMobil}
                    alt="logo popkode"
                    onClick={onLogoPress}
                    className="logoMobil"
                />

                <img 
                    width={40}
                    src={Menu}
                    alt="Menu icon"
                    onClick={onToggleMenu}
                    className="menuIcon"
                />
            </div>
        )
    }, [onLogoPress, onToggleMenu, scrolled]);

    const renderMobilModalMenu = useCallback(()=> {
        if(!isOpenMenu) return;

        return (
            <div className="modalContainer">
                <div 
                    className="closeModalMenuContainer"
                    onClick={onToggleMenu}
                />

                <div
                    className="mobilMenuContainer"
                >
                    <img 
                        width={150}
                        src={LogoMobil}
                        alt="logo popkode extend"
                        onClick={handleModalLogoPress}
                        className="logoHeader"
                    />

                    <div className="navItemsMobilContainer">
                    {
                        navItems.map((item, i)=> {
                            return (
                                <label
                                    key={i}
                                    className="navItemMobil"
                                    onClick={()=> {
                                        onNavItemPress(item.ref);
                                        onToggleMenu();
                                    }}
                                >
                                    {item.name}
                                </label>
                            )
                        })
                    }
                    </div>

                    {renderGetInTouch()}
                </div>
            </div>
        );
    }, [
        isOpenMenu, 
        onToggleMenu, 
        handleModalLogoPress, 
        navItems, 
        renderGetInTouch, 
        onNavItemPress,
    ]);

    return (
        <div 
            className={
                localLanguage === LanguagesEnum.FR ? 
                "headerContainer" : "headerContainer_EN"
            }
        >
            
            <Fade top>
                {
                    isDesktop ? 
                    renderDesktopNav() : renderMobilNav()
                }
            </Fade>
            
            <Slide bottom>
                {
                    isAndroid ? (
                        <div 
                            className={
                                scrolled ? "actionButton_ANDROID_Container actionButton_ANDROID_Container--scrolled" 
                                : "actionButton_ANDROID_Container"
                            }
                        >
                            {
                                <ActionButton 
                                    localLanguage={localLanguage}
                                    onContactUsPress={onContactUsPress}
                                />
                            }
                        </div>
                    ) : (
                        <div 
                            className={
                                scrolled ? "actionButtonContainer actionButtonContainer--scrolled" 
                                : "actionButtonContainer"
                            }
                        >
                            {
                                <ActionButton 
                                    localLanguage={localLanguage}
                                    onContactUsPress={onContactUsPress}
                                />
                            }
                        
                        </div>
                    )
                }
            </Slide>

            {renderMobilModalMenu()}
        </div>
    )
}