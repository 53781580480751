import { useEffect, useState } from "react";

export const useScreensize = ()=> {

    const [isDesktop, setIsDesktop] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isAndroid, setIsAndroid] = useState<boolean>(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        if(windowWidth <= 950){
            setIsMobile(true);
            setIsDesktop(false);
        } else {
            setIsDesktop(true);
            setIsMobile(false);
        }

        getDeviceType();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowWidth]);

    const getDeviceType = ()=> {
        const userAgent = navigator.userAgent || navigator.vendor;
      
        if (/android/i.test(userAgent)) {
            setIsAndroid(true);
        }
        // if (/iPad|iPhone|iPod/.test(userAgent)) {
        //   return "iphone";
        // }
    }

    return {
        isDesktop,
        isMobile,
        isAndroid
    }
}