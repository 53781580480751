import React, { useCallback, useMemo, useState } from 'react';
import './App.css';
import { 
  ActionButton,
  Header, 
  LogosList, 
  Method, 
  OurWork, 
  SelectLanguagesButton, 
  Team,
} from './components';

import LogoMin from './assets/logos/logo_min.png';
import LogoTxt from './assets/logos/logo_mobil.svg';
import GroupAndroidAndApple from './assets/logos/GroupAndroidAndApple.png';
import ReactNativeLogo from './assets/logos/React-icon.png';
import { useScreensize } from './hooks';

//@ts-ignore
import Slide from 'react-reveal/Slide';
import { language } from './functions';
import { LanguagesEnum } from './enums';

const TYPE_FORM_URL = 'https://18g5bd53a65.typeform.com/to/O4JeL5gm';
const PHONE_NUMBER = 'tel:0675278795';
const MAIL = 'mailto:hello@popkode.fr';

export default function App() {

  const [localLanguage, setLocalLanguage] = useState<string>(language);

  const {isMobile} = useScreensize();
  const handleGotToAnchor = useCallback((id: string)=> {
    window.location.href = id;
  }, []);

  const handleGoToTypeForm = useCallback(()=> {
    window.location.href = TYPE_FORM_URL;
  }, []);

  const handleOpenPhoneNumber = useCallback(()=> {
    window.open(PHONE_NUMBER);
  }, []);

  const handleOpenEmail = useCallback(()=> {
    window.open(MAIL);
  }, []);

  const handleLogoPress = useCallback(()=> {
    window.location.href = '#popkode';
  }, []);

  return (
    <div className="container">

      <SelectLanguagesButton 
        activeCode={localLanguage}
        onChange={(e)=> {
          setLocalLanguage(e)
        }}
      />

      <div id='popkode' />
      <Header 
        localLanguage={localLanguage}
        onContactUsPress={handleGoToTypeForm}
        onLogoPress={handleLogoPress}
        onNavItemPress={handleGotToAnchor}
      />

      <div id="references">
        <LogosList />
      </div>

      <OurWork 
        localLanguage={localLanguage}
      />

      <div id='team' />
      <Team 
        localLanguage={localLanguage}
      />

      <Slide bottom>
        <div className='sectionWithBg' />
      </Slide>

      <div id='method' />
      <div className="methodTxtContainer">
        <Slide bottom>
          {
            localLanguage === LanguagesEnum.FR ? (
              <p className="methodTitle">
                Une <label className="methodTxtLabel">méthode </label> 
                ciblée et efficace, pour un résultat toujours 
                <label className="attrayantTxtLabel"> attrayant.</label>
              </p>
            ) : (
              <p className="methodTitle">
                A <label className="methodTxtLabel">targeted </label> 
                and efficient method, for consistently appealing
                <label className="attrayantTxtLabel"> results.</label>
              </p>
            )
          }
          
        </Slide>

        <Slide bottom>
          {
            localLanguage === LanguagesEnum.FR ? (
              <p className="methodDescription">
                Notre approche repose sur plusieurs éléments essentiels, incorporer régulièrement vos feedbacks grâce à un cycle de rétroaction rapide, favoriser la communication directe avec notre équipe technique et considérer vos défis opérationnels et commerciaux à chaque phase : 
                {
                  !isMobile && (
                    <>
                      <label> cadrage de votre projet, la conception graphique, le développement technique</label> et enfin 
                      <label> l’évolution de votre produit.</label>
                    </>
                  )
                }
              </p>
            ) : (
              <p className="methodDescription">
                Our approach is based on several essential elements: regularly incorporating your feedback through a fast feedback loop, promoting direct communication with our technical team, and considering your operational and business challenges at every phase:                {
                !isMobile && (
                  <>
                    <label> scoping your project, graphic design, technical development,</label> and
                    <label> the evolution of your product.</label>
                  </>
                )
                }
              </p>
            )
          }
        </Slide>
        {
          isMobile && (
            <Slide bottom>
              <div className='methodDescriptionListContainer'>
                <p className='methodDescriptionListTxt'>
                  👋🏼. {
                    localLanguage === LanguagesEnum.FR ? 'le cadrage de votre projet' : 'Project framing'
                  }
                </p>  
                <p className='methodDescriptionListTxt'>
                  🧑🏼‍🎨. {
                    localLanguage === LanguagesEnum.FR ? 'la conception graphique' : 'Graphic design'
                  }
                </p>  
                <p className='methodDescriptionListTxt'>
                  🧠. {
                    localLanguage === LanguagesEnum.FR ? "le développement technique" : 'Technical development'
                  }
                </p>  
                <p className='methodDescriptionListTxt'>
                  🏆. {
                    localLanguage === LanguagesEnum.FR ? "l’évolution de votre produit" : 'Product evolution'
                  }
                </p> 
              </div>
            </Slide>
          )
        }
      </div>
      <Method 
        localLanguage={localLanguage}
      /> 
      
      <div className='section_2'>
        <div className='mvpDescription'>
          {
            !isMobile && (
              <Slide bottom>
                {
                  localLanguage === LanguagesEnum.FR ? (
                    <p className='mvpDescTxt_1'>
                      Ce que nos clients demandent le plus
                    </p>
                  ) : (
                    <p className='mvpDescTxt_1'>
                      What our clients request the most
                    </p>
                  )
                }
              </Slide>
            )
          }
          <Slide bottom>
            {
              localLanguage === LanguagesEnum.FR ? (
                <p className='mvpDescTxt_2'>
                  Besoin d’un MVP <label>rapidement</label>, à la fois <label>esthétique</label>, 
                  <label> performant</label> et à un <label>prix abordable?</label>
                </p>
              ) : (
                <p className='mvpDescTxt_2'>
                  Need an MVP <label>quickly</label>, that's both <label>aesthetically</label>, 
                  <label> pleasing, high-performing</label> and <label>affordable?</label>
                </p>
              )
            }
          </Slide>

          <Slide bottom>
            <ActionButton 
              localLanguage={localLanguage}
              onContactUsPress={handleGoToTypeForm}
            />
          </Slide>
        </div>
        {
          isMobile ? (
            <Slide bottom>
              <div className='androidAndIosBGMobile'>
                <img 
                  src={ReactNativeLogo}
                  className='ReactNativeLogo'
                  alt='react native logo'
                />
                {
                  localLanguage === LanguagesEnum.FR ? (
                    <p className='androidAndIosBGMobileDesciption'>
                      Nous utilisons <label>React Native </label> 
                      pour développer les applications mobiles de nos clients depuis notre création. 
                      <label> React Native</label> est notamment utilisé par Facebook, Uber, Instagram et Walmart. Il permet un rendu natif, fluide et performant avec un seul code à développer et à maintenir simultanément sur 
                      <label> iOS</label> et <label>Android</label>.
                    </p>
                  ) : (
                    <p className='androidAndIosBGMobileDesciption'>
                      We have been using <label>React Native </label> 
                      to develop mobile applications for our clients since our inception.
                      <label> React Native</label> React Native is also used by Facebook, Uber, Instagram, and Walmart. It allows for native, smooth, and high-performance rendering with a single codebase to be developed and maintained simultaneously on
                      <label> iOS</label> and <label>Android</label>.
                    </p>
                  )
                }
                <img 
                  src={GroupAndroidAndApple}
                  className='GroupAndroidAndApple'
                  alt='apple and android logos'
                />
              </div>
            </Slide>
          ) : (
            <div className='reactToAndroiAndIosApp'>
              <Slide left>
                <div className={
                  localLanguage === LanguagesEnum.FR ? 
                  'reactNativeBG' : 'reactNativeBG_EN'
                  }
                />
              </Slide>
              <Slide right>
                <div 
                  className={
                    localLanguage === LanguagesEnum.FR ?
                    'androidAndIosBG' : 'androidAndIosBG_EN'
                  }
                />
              </Slide>
            </div>
          )
        }
      </div>

      <div className='footerContainer'>
          <div>
            <div className='footerLogoContainer'>
              <Slide left>
                <img 
                  src={LogoTxt}
                  className='footerLogo'
                  alt='logo'
                />
              </Slide>
            </div>
            <div className='adressContainer'>
              <Slide left>
                <p className='adresss'>
                  📍 93 rue Montmartre
                </p>
              </Slide>
              <Slide left>
                <p className='adresss'>
                  75002 Paris, France
                </p>
              </Slide>
            </div>
          </div>

        <Slide bottom>
          <img 
            src={LogoMin} 
            alt="popkode logo" 
            height={115}
            className='logoMinStyle'
          />
        </Slide>

          <div>
            <Slide right>
              <p 
                className='adresss'
                onClick={handleOpenEmail}
              >
                📩 hello@popkode.fr
              </p>
            </Slide>
            <Slide right>
              <p 
                className='adresss'
                onClick={handleOpenPhoneNumber}
              >
                📞 06 75 27 87 95
              </p>
            </Slide>
            <Slide right>
              <p 
                className='adresss'
              >
                ⚖️ {localLanguage === LanguagesEnum.FR ? 'Mentions légales' : 'Legal Notices'}
              </p>
            </Slide>
          </div>
      </div>
    </div>
  );
}
