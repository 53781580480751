import React from "react";
import './styles.css';
import { OurWorkProps } from "./interfaces";
import { useScreensize } from "../../hooks";

//@ts-ignore
import Slide from 'react-reveal/Slide';
import { LanguagesEnum } from "../../enums";

export const OurWork:React.FC<OurWorkProps> = ({
    localLanguage
})=> {

    const {isDesktop} = useScreensize();

    return (
        <div className="ourWorkContainer">
            <Slide bottom>
                {
                    localLanguage === LanguagesEnum.FR ? (
                        <p className="ourWorkTitle">
                            <label className="concevoir">Concevoir. </label>
                            <label className="coder">Coder. </label>
                            <label>Tester. Évoluer.</label>
                        </p>
                    ) : (
                        <p className="ourWorkTitle">
                            <label className="concevoir">Design. </label>
                            <label className="coder">Code. </label>
                            <label>Test. Evolve.</label>
                        </p>
                    )
                }
            </Slide>

            <div className="ourWorkTextsContainer">
                <Slide bottom>
                    {
                        localLanguage === LanguagesEnum.FR ? (
                            <p 
                                className="ourWorkText"
                                style={{
                                    marginRight: isDesktop ? 33 : 0,
                                    marginBottom: isDesktop ? 0 : 20
                                }}
                            >
                                Notre objectif est d'atteindre <label>l’excellence 🥇 </label> 
                                pour tous nos clients en adoptant une approche pragmatique et en maintenant une 
                                <label> communication transparente</label> avec vous et votre équipe tout au long du processus de développement.
                            </p>
                        ) : (
                            <p 
                                className="ourWorkText"
                                style={{
                                    marginRight: isDesktop ? 33 : 0,
                                    marginBottom: isDesktop ? 0 : 20
                                }}
                            >
                                Our goal is to achieve <label>excellence 🥇 </label> 
                                or all our clients by taking a pragmatic approach and maintaining 
                                <label> transparent communication</label> with you and your team throughout the development process.
                            </p>
                        )
                    }
                </Slide>

                <Slide bottom>
                    {
                        localLanguage === LanguagesEnum.FR ? (
                            <p 
                                className="ourWorkText ourWorkTextMobil"
                                style={{
                                    marginLeft: isDesktop ? 33 : 0
                                }}
                            >
                                Nous sommes habitués à gérer des projets présentant des 
                                <label> challenges techniques et commerciaux majeurs, </label> 
                                que ce soit pour des start-ups ou des grandes entreprises. Nous adaptons nos méthodes en fonction des besoins et sommes prêts à relever tous les défis.
                            </p>
                        ) : (
                            <p 
                                className="ourWorkText ourWorkTextMobil"
                                style={{
                                    marginLeft: isDesktop ? 33 : 0
                                }}
                            >
                                We are accustomed to managing projects with 
                                <label> significant technical and business challenges,  </label> 
                                whether for startups or large corporations. We adapt our methods according to the needs and are ready to tackle any challenges.
                            </p>
                        )
                    }
                </Slide>
            </div>
        </div>
    )
}