import React from "react";
import './styles.css';
import { LogosListProps, LOGO_LIST } from "./interfaces";

//@ts-ignore
import Slide from 'react-reveal/Slide';

export const LogosList:React.FC<LogosListProps> = ({
    
})=> {

    

    return (
        <div className="logoContainer">
            {
                LOGO_LIST.map((el, i)=> (
                    <Slide bottom>
                        <img 
                            key={i}
                            className="logo" 
                            src={el}
                            alt={el}
                        />
                    </Slide>
                    
                ))
            }
        </div>
    )
}