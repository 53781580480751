import React, { useCallback, useMemo, useState } from "react";
import './styles.css';
import { SelectLanguagesButtonProps } from "./interfaces";
import { LanguagesEnum } from "../../enums";

import UK_FLAG from '../../assets/flags/UK_flag.png';
import FR_FLAG from '../../assets/flags/FR_flag.png';

export const SelectLanguagesButton:React.FC<SelectLanguagesButtonProps> = ({
    activeCode,
    onChange,
})=> {

    const [showButton, setShowButton] = useState<boolean>(false);

    const activeLabel = useMemo(()=> {
        return activeCode === LanguagesEnum.FR ? 'Français' : 'English'
    }, [activeCode]);

    const inActiveLabel = useMemo(()=> {
        return activeCode === LanguagesEnum.FR ? 'English' : 'Français'
    }, [activeCode]);

    const activeFlag_URI = useMemo(()=> {
        return activeCode === LanguagesEnum.FR ? FR_FLAG : UK_FLAG
    }, [activeCode]);

    const inActiveFlag_URI = useMemo(()=> {
        return activeCode === LanguagesEnum.FR ? UK_FLAG : FR_FLAG
    }, [activeCode]);

    const onToggleButton = useCallback(()=> {
        setShowButton(!showButton);
    }, [showButton]);

    const onChangeLanguage = useCallback(()=> {
        const code = activeCode === LanguagesEnum.FR ? LanguagesEnum.EN : LanguagesEnum.FR
        onChange(code)
        onToggleButton();
    }, [
        activeCode, 
        onChange, 
        onToggleButton,
    ])

    return (
        <div className="position">
            {
                showButton && (
                    <div 
                        className="buttonStyle"
                        onClick={onChangeLanguage}
                    >
                        <img 
                            className='flagIcon'
                            src={inActiveFlag_URI} 
                            alt="flag icon" 
                        />
                        <label>
                            {inActiveLabel}
                        </label>
                    </div>
                )
            }
            <div 
                className="buttonStyle"
                onClick={onToggleButton}
            >
                <img 
                    className='flagIcon'
                    src={activeFlag_URI} 
                    alt="flag icon" 
                />
                <label>
                    {activeLabel}
                </label>
            </div>
        </div>
    )
}