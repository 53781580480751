export interface LogosListProps {
   
}

export const LOGO_LIST = [
    "https://firebasestorage.googleapis.com/v0/b/popcode-fr.appspot.com/o/logos%2FBNP-Paribas-Logo-2000%201.png?alt=media&token=7d517c24-b47c-4e9b-9d4b-711961e5114a",
    "https://firebasestorage.googleapis.com/v0/b/popcode-fr.appspot.com/o/logos%2Fimage%202.png?alt=media&token=41bdfbc3-2ac9-4121-ae12-6a45f2376001",
    "https://firebasestorage.googleapis.com/v0/b/popcode-fr.appspot.com/o/logos%2Fausy_logo-removebg-preview%201.png?alt=media&token=3022bdbf-4c71-4716-8789-2a66a2b41c10",
    "https://firebasestorage.googleapis.com/v0/b/popcode-fr.appspot.com/o/logos%2Fsd%201.png?alt=media&token=c7d99c8e-9e43-49ff-9aa2-9e800e22b71e",
    "https://firebasestorage.googleapis.com/v0/b/popcode-fr.appspot.com/o/logos%2Flogo%20Byme%202%202.png?alt=media&token=7fe83969-7027-45f2-b8cd-4cdea40991f0",
    "https://firebasestorage.googleapis.com/v0/b/popcode-fr.appspot.com/o/logos%2Fimage%204.png?alt=media&token=7d67c374-ad2e-424d-99be-228f812ceb85",
    "https://firebasestorage.googleapis.com/v0/b/popcode-fr.appspot.com/o/logos%2FLogo_Softia_color_2%201.png?alt=media&token=8c5b99aa-dfd5-49b2-9136-79d57528a9ff",
    "https://firebasestorage.googleapis.com/v0/b/popcode-fr.appspot.com/o/logos%2Fla_capsule%201.png?alt=media&token=34ba197b-bc46-407c-b8d5-6408657068ae",
    "https://firebasestorage.googleapis.com/v0/b/popcode-fr.appspot.com/o/logos%2FBurton_Menswear_logo%201.png?alt=media&token=f7ad1c51-3b55-4f93-b494-e5426a1543c3",
];
